import styled from "styled-components";
import Mybet  from "./mybet.webp";
import Loader from "../../ui/Loader/Loader";

type LoaderProps = {
    width?: number,
    height?: number
}

const AppLoader = (props: LoaderProps): JSX.Element => {
    return (
        <Wrapper>
            <Loader width={300} />
            <img alt={'logo-max-gold'} src={Mybet} style={{width: 200}} />
        </Wrapper>
    );
};

export default AppLoader;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 10000000;
  background-color: ${({ theme }) => theme.background.main};
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`
